<template>
  <OsCard v-bind="$attrs" class="tags-card">
    <OsCardTitle v-if="title">{{ title }}</OsCardTitle>
    <OsCardContent v-if="tags.length">
      <BadgeItem v-if="getCustomerType" dense dark>
        {{ getCustomerType }}
      </BadgeItem>
      <TagList :tags="tags" />
    </OsCardContent>
  </OsCard>
</template>

<script lang="ts">
import { ECustomerTypes } from '~/types/customerType'

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    tags: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => []
    },
    customerType: {
      type: String as PropType<ECustomerTypes>,
      required: false,
      default: ''
    }
  },

  setup(props) {
    const getCustomerType = computed<string>(() => {
      return props.customerType !== ECustomerTypes.Private
        ? props.customerType
        : ''
    })

    return { getCustomerType }
  }
}
</script>

<style lang="scss">
// if TagsCard type="plain" is used within another card (e.g SavedSearchItem)
.os-card .os-card--plain.tags-card {
  & > .os-card__content {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.badge {
  margin-bottom: rem(8);
}
</style>
